<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2 space-y-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>

      <form
        class="space-y-6 pb-4"
        @submit.prevent="submit"
        @keydown="removeError($event.target.id)"
        @input="removeError($event.target.id)">
        <h3 class="text-2xl">
          {{ (isInscription || isReinscription) ? 'Re' : isUpdating ? 'Actualizar' : 'Crear' }} inscripción
        </h3>

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Datos del alumno
          </h3>
          <a-input
              id="id_document"
              required
              :label="`Cédula ${loadingStudents || loadings.reinscription ? 'Buscando...' : '' }`"
              :loading="loadingStudents || loadings.reinscription"
              @input="handleTypingSearch"
              :error="error('id_document', { attribute: 'cédula' })"
              v-model="preinscription.id_document" />

          <div class="flex flex-row space-x-2 sm:flex-column">
            <a-input
              label="Nombres"
              class="w-full"
              :required="!isReinscription"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              id="first_name"
              :error="error('first_name', { attribute: 'nombre' })"
              v-model.number="preinscription.first_name" />

              <div class="flex flex-col w-full">
                <a-input
                  class="w-full mt-6"
                  placeholder="Segundo (opcional)"
                  id="second_name"
                  :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
                  :error="error('second_name', { attribute: 'segundo nombre' })"
                  v-model.number="preinscription.second_name" >
                  <template  #suffix>
                    <div @click="handleDisplay(true)">
                      <a-icon v-if="displayNotApplyName" id="second_name_arrow" name="arrow-down" class="mr-2 cursor-pointer transform rotate-180" />
                      <a-icon v-else name="arrow-down" class="mr-2 cursor-pointer" />
                    </div>
                  </template>
                </a-input>
                <ul v-show="displayNotApplyName" class="bg-white border rounded-md shadow-lg z-50 w-full">
                  <li @click="handleSelection('No aplica', true)" class="px-3 py-2 rounded-md hover:bg-blue-600 hover:text-white cursor-pointer">
                    No aplica
                  </li>
                </ul>
              </div>
          </div>

          <div class="flex flex-row space-x-2 sm:flex-column">
            <a-input
              label="Apellidos"
              class="w-full"
              :required="!isReinscription"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              id="last_name"
              :error="error('last_name', { attribute: 'apellido' })"
              v-model="preinscription.last_name" />
              <div class="flex flex-col w-full">
                <a-input
                  class="w-full mt-6"
                  placeholder="Segundo (opcional)"
                  id="second_last_name"
                  :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
                  :error="error('second_last_name', { attribute: 'segundo apellido' })"
                  v-model="preinscription.second_last_name">
                  <template  #suffix>
                    <div @click="handleDisplay(false)">
                      <a-icon v-if="displayNotApplyLastName" id="second_last_name_arrow" name="arrow-down" class="mr-2 cursor-pointer transform rotate-180" />
                      <a-icon v-else name="arrow-down" class="mr-2 cursor-pointer" />
                    </div>
                  </template>
                </a-input>
                <ul v-show="displayNotApplyLastName" class="bg-white border rounded-md shadow-lg z-50 w-full">
                  <li @click="handleSelection('No aplica', false)" class="px-3 py-2 rounded-md hover:bg-blue-600 hover:text-white cursor-pointer">
                    No aplica
                  </li>
                </ul>
              </div>
          </div>

          <template v-if="!isUpdating">
            <a-input
              :required="!isReinscription"
              label="Correo electrónico"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              type="email"
              id="email"
              :error="error('email', { attribute: 'correo electrónico' })"
              v-model="preinscription.email" />
          </template>

          <a-select
            label="Género"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            :source="[{label: 'Masculino', value: 'male'}, {label: 'Femenino', value: 'female'}]"
            :error="error('gender', { attribute: 'género' })"
            v-model="preinscription.gender" />
            
          <div class="flex flex-row space-x-2">
            <a-input
              label="Teléfono"
              class="w-full"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              id="phone"
              :error="error('phone', { attribute: 'teléfono' })"
              v-model="preinscription.phone" />

            <a-input
              label="Teléfono celular"
              class="w-full"
              :required="!isReinscription"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              id="mobile_phone"
              :error="error('mobile_phone', { attribute: 'teléfono celular' })"
              v-model="preinscription.mobile_phone" />
          </div>

          <div class="flex flex-row space-x-2">
            <a-input
              label="Lugar de nacimiento"
              class="w-full"
              :required="!isReinscription"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              id="birth_place"
              :error="error('birth_place', { attribute: 'lugar de nacimiento' })"
              v-model="preinscription.birth_place" />
  
            <a-input
              label="Fecha de nacimiento"
              class="w-full"
              :required="!isReinscription"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              id="birth_date"
              type="date"
              :error="error('birth_date', { attribute: 'fecha de nacimiento', format: (value) => value, date: (value) => value })"
              v-model="preinscription.birth_date" />
          </div>
          <div>
            <div class="flex">
              <label class="font-medium block mb-2">
                ¿Posee alguna condición?
              </label>
              <span class="text-red-600 inline-block ml-1 font-bold">
                *
              </span>
            </div>
            <div class="flex flex-row space-x-4">
              <label class="inline-flex items-center">
                <input type="radio" 
                  name="has_condition"
                  :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
                  :value="false"
                  v-model="hasCondition" 
                  class="form-radio h-4 w-4 text-blue-600">
                <span class="ml-2">No</span>
              </label>
              <label class="inline-flex items-center">
                <input type="radio" 
                  name="has_condition"
                  :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
                  :value="true"
                  v-model="hasCondition" 
                  class="form-radio h-4 w-4 text-blue-600">
                <span class="ml-2">Sí</span>
              </label>
            </div>
            
            <a-input
              v-if="hasCondition"
              label="Especifique su condición"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              required
              id="personal_condition"
              :error="error('personal_condition', { attribute: 'condición' })"
              v-model="preinscription.personal_condition"
              class="mt-4" />
          </div>
          <div class="space-y-4">
            <h3 class="font-bold text-gray-800 text-lg">
              Dirección
            </h3>
            <div class="flex flex-row space-x-2">
              <a-input
                label="Estado"
                :required="!isReinscription"
                :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
                class="w-full"
                id="state"
                :error="error('state', { attribute: 'estado' })"
                v-model="preinscription.address.state" />
              <a-input
                label="Ciudad"
                :required="!isReinscription"
                :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
                class="w-full"
                id="city"
                :error="error('city', { attribute: 'ciudad' })"
                v-model="preinscription.address.city" />
            </div>
            <div>
              <div class="flex">
                <label class="font-medium block mb-2">
                    Ubicación de vivienda
                </label>
                <span class="text-red-600 inline-block ml-1 font-bold">
                    *
                </span>
              </div>
              <div class="flex flex-row space-x-4">
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_location" 
                    value="Urbanización" 
                    v-model="preinscription.address.location"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_location')">
                  <span class="ml-2">Urbanización</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_location" 
                    value="Barrio" 
                    v-model="preinscription.address.location"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_location')">
                  <span class="ml-2">Barrio</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_location" 
                    value="Sector" 
                    v-model="preinscription.address.location"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_location')">
                  <span class="ml-2">Sector</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_location" 
                    value="Avenida" 
                    v-model="preinscription.address.location"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_location')">
                  <span class="ml-2">Avenida</span>
                </label>
              </div>
              <p v-if="error('address_location', { attribute: 'ubicación' })" class="mt-2 text-sm text-red-600">
                {{ error('address_location', { attribute: 'ubicación' }) }}
              </p>
            </div>
            <a-input
              required
              id="address"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              :error="error('address', { attribute: 'dirección' })"
              v-model="preinscription.address.address" />
            <div>
              <div class="flex">
                <label class="font-medium block mb-2">
                    Tipo de vivienda
                </label>
                <span class="text-red-600 inline-block ml-1 font-bold">
                    *
                </span>
              </div>
              <div class="flex flex-row space-x-4">
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="edificio" 
                    v-model="preinscription.address.type"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Edificio</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="residencia" 
                    v-model="preinscription.address.type"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Residencia</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="conjunto residencial" 
                    v-model="preinscription.address.type"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Conjunto Residencial</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="quinta" 
                    v-model="preinscription.address.type"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Quinta</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="casa" 
                    v-model="preinscription.address.type"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Casa</span>
                </label>
              </div>
              <p v-if="error('address_type', { attribute: 'tipo' })" class="mt-2 text-sm text-red-600">
                {{ error('address_type', { attribute: 'tipo' }) }}
              </p>
            </div>
            <a-input
              required
              id="address_number"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              :error="error('address_number', { attribute: 'número' })"
              v-model="preinscription.address.number" />
          </div>
        </div>

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Redes sociales
          </h3>
          <a-input
            label="Instagram"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="social_networks.instagram"
            :error="error('social_networks.instagram', { attribute: 'instagram' })"
            v-model="preinscription.social_networks.instagram" />

          <a-input
            label="Facebook"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="social_networks.facebook"
            :error="error('social_networks.facebook', { attribute: 'facebook' })"
            v-model="preinscription.social_networks.facebook" />
          <a-input
            label="Twitter"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="social_networks.twitter"
            :error="error('social_networks.twitter', { attribute: 'twitter' })"
            v-model="preinscription.social_networks.twitter" />
        </div>

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Estudios realizados
          </h3>

          <a-select
            label="Educación"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            :source="[{label: 'Media', value: 'medium'}, {label: 'Superior', value: 'superior'}]"
            :error="error('educational_background', { attribute: 'educación' })"
            v-model="preinscription.educational_background" />

          <a-input
            label="Título obtenido"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="educational_title"
            :error="error('educational_title', { attribute: 'título obtenido' })"
            v-model="preinscription.educational_title" />

          <a-input
            label="Actividad actual"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="educational_current_activity"
            :error="error('educational_current_activity', { attribute: 'actividad actual' })"
            v-model="preinscription.educational_current_activity" />

          <a-input
            label="Nombre de instituto"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="educational_institution_name"
            :error="error('educational_institution_name', { attribute: 'nombre de instituto' })"
            v-model="preinscription.educational_institution_name" />

          <a-input
            label="Fecha de graduación"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="educational_graduation_date"
            type="date"
            :error="error('educational_graduation_date', { attribute: 'fecha de graduación', format: (value) => value, date: (value) => value })"
            v-model="preinscription.educational_graduation_date" />

          <a-select
            :label="isReinscription ? 'Nueva especialidad' : 'Especialidad'"
            required
            id="specialization_id"
            :disabled="loadingStudents || loadings.reinscription || isFounded"
            @input="removeError('specialization_id')"
            :loading="loadingSpecialization"
            :source="specializationsData"
            :error="error('specialization_id', { attribute: 'especialidad' })"
            v-model="preinscription.specialization_id"/>
          <small v-if="changeStudentSpecialization.target_specialization_id && isReinscription">
            {{ preinscription.first_name }} se inscribirá en el <strong>semestre {{ semesterNumber }}</strong> del <strong>{{ pensumName }}</strong>
          </small>
          <div>
            <div class="flex">
              <label class="font-medium text-gray-700 block mb-2">
                Turno
              </label>
              <span class="text-red-600 inline-block ml-1 font-bold">
                *
            </span>
            </div>

            <v-select
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              :loading="loadings.enabledPeriod"
              :options="period.settings.schedule_shifts"
              :reduce="$0 => $0.value"
              v-model="preinscription.schedule_shift" />
            <p class="text-red-500" v-if="error('schedule_shift')">{{ error('schedule_shift', { attribute: 'turno' }) }}</p>
          </div>
        </div>

        <!-- ------------------------------------- -->

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Datos de persona de contacto
          </h3>
          
          <a-input
            label="Nombre y apellido"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="representative_name"
            :error="error('representative_name', { attribute: 'nombre de la persona de contacto' })"
            v-model="preinscription.representative_name" />

          <a-input
            label="Email"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="representative_email"
            :error="error('representative_email', { attribute: 'email de la persona de contacto' })"
            v-model="preinscription.representative_email" />

          <a-input
            label="Profesión"
            :required="!isReinscription"
            :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
            id="representative_profession"
            :error="error('representative_profession', { attribute: 'profesión de la persona de contacto' })"
            v-model="preinscription.representative_profession" />

          <div class="space-y-4">
            <h3 class="font-bold text-gray-800 text-lg">
              Dirección
            </h3>
            <div class="flex items-center">
              <input
                type="checkbox"
                class="mr-2"  
                :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
                id="representative_address"
                v-model="sameAsStudentAddress"
                @input="(e) => handleSameAsStudentAddress(e.target.checked)"
              />
                La dirección de la persona de contacto es la misma que la del estudiante
            </div>
            <div class="flex flex-row space-x-2">
              <a-input
                label="Estado"
                class="w-full"
                :required="!isReinscription"
                :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
                id="representative_state"
                :error="error('representative_state', { attribute: 'estado de la persona de contacto' })"
                v-model="preinscription.representative_address.state" />
              <a-input
                label="Ciudad"
                class="w-full"
                :required="!isReinscription"
                :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
                id="representative_city"
                :error="error('representative_city', { attribute: 'ciudad de la persona de contacto' })"
                v-model="preinscription.representative_address.city" />
            </div>
            <div>
              <div class="flex">
                <label class="font-medium block mb-2">
                    Ubicación de vivienda
                </label>
                <span v-if="!sameAsStudentAddress" class="text-red-600 inline-block ml-1 font-bold">
                    *
                </span>
              </div>
              <div class="flex flex-row space-x-4">
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_location" 
                    value="Urbanización"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
                    :required="!sameAsStudentAddress" 
                    v-model="preinscription.representative_address.location" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_location')">
                  <span class="ml-2">Urbanización</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_location" 
                    value="Barrio"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
                    :required="!sameAsStudentAddress"  
                    v-model="preinscription.representative_address.location" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_location')">
                  <span class="ml-2">Barrio</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_location" 
                    value="Sector"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
                    :required="!sameAsStudentAddress"  
                    v-model="preinscription.representative_address.location" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_location')">
                  <span class="ml-2">Sector</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_location" 
                    value="Avenida"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
                    :required="!sameAsStudentAddress"  
                    v-model="preinscription.representative_address.location" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_location')">
                  <span class="ml-2">Avenida</span>
                </label>
              </div>
              <p v-if="error('representative_address_location', { attribute: 'ubicación' })" class="mt-2 text-sm text-red-600">
                {{ error('representative_address_location', { attribute: 'ubicación' }) }}
              </p>
            </div>

            <a-input
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
              :required="!sameAsStudentAddress"
              id="representative_address"
              :error="error('representative_address', { attribute: 'dirección' })"
              v-model="preinscription.representative_address.address" />

            <div>
              <div class="flex">
                <label class="font-medium block mb-2">
                    Tipo de vivienda
                </label>
                <span v-if="!sameAsStudentAddress" class="text-red-600 inline-block ml-1 font-bold">
                    *
                </span>
              </div>
              <div class="flex flex-row space-x-4">
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_type" 
                    value="edificio"
                    :required="!sameAsStudentAddress" 
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
                    v-model="preinscription.representative_address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_type')">
                  <span class="ml-2">Edificio</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_type" 
                    value="residencia"
                    :required="!sameAsStudentAddress" 
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
                    v-model="preinscription.representative_address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_type')">
                  <span class="ml-2">Residencia</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_type" 
                    value="conjunto residencial"
                    :required="!sameAsStudentAddress"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress" 
                    v-model="preinscription.representative_address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_type')">
                  <span class="ml-2">Conjunto Residencial</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_type" 
                    value="quinta"
                    :required="!sameAsStudentAddress"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress" 
                    v-model="preinscription.representative_address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_type')">
                  <span class="ml-2">Quinta</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="representative_address_type" 
                    value="casa"
                    :required="!sameAsStudentAddress"
                    :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress" 
                    v-model="preinscription.representative_address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('representative_address_type')">
                  <span class="ml-2">Casa</span>
                </label>
              </div>
              <p v-if="error('representative_address_type', { attribute: 'tipo' })" class="mt-2 text-sm text-red-600">
                {{ error('representative_address_type', { attribute: 'tipo' }) }}
              </p>
            </div>
            <a-input
              :required="!sameAsStudentAddress"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription || sameAsStudentAddress"
              id="representative_address_number"
              :error="error('representative_address_number', { attribute: 'número' })"
              v-model="preinscription.representative_address.number" />
          </div>
        </div>

        <!-- ------------------------------------------ -->

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Datos de pago
          </h3>
          <div class="flex">
            <label class="font-medium block mb-1">
              Tipo de pago
            </label>
            <span class="text-red-600 inline-block ml-1 font-bold">
              *
            </span>
          </div>
          <div class="flex flex-row space-x-4">
            <label class="inline-flex items-center">
              <input type="radio" 
                id="cash_down" 
                value="cash_down"
                v-model="preinscription.payment_option"
                :disabled="loadingStudents || loadings.reinscription || isFounded"
                :error="error('payment_option', { attribute: 'de contado' })" 
                class="form-radio h-4 w-4 text-blue-600">
              <span class="ml-2">De contado</span>
            </label>
            <label class="inline-flex items-center">
              <input type="radio" 
                id="financed" 
                value="financed"
                v-model="preinscription.payment_option"
                :disabled="loadingStudents || loadings.reinscription || isFounded"
                :error="error('payment_option', { attribute: 'financiado' })" 
                class="form-radio h-4 w-4 text-blue-600">
              <span class="ml-2">Financiado</span>
            </label>
          </div>
          <a-input
            label="Fecha de pago"
            required
            :disabled="loadingStudents || loadings.reinscription || isFounded"
            id="payment_date"
            type="date"
            :error="error('payment_date', { attribute: 'fecha de pago', format: (value) => value, date: (value) => value })"
            v-model="preinscription.payment_date" />
          <a-input
            label="Total pagado"
            required
            :disabled="loadingStudents || loadings.reinscription || isFounded"
            type="number"
            id="payment_amount"
            :error="error('payment_amount', { attribute: 'total pagado' })"
            v-model.number="preinscription.payment_amount" />
          <a-select
            label="Tipo de pago"
            required
            :disabled="loadingStudents || loadings.reinscription || isFounded"
            :source="[{label: 'Efectivo', value: 1}, {label: 'Transferencia bancaria', value: 3}, {label: 'Zelle', value: 5}]"
            :error="error('payment_type', { attribute: 'turno' })"
            v-model="preinscription.payment_type" />
          <a-input
                label="Número de factura"
                required
                :disabled="loadingStudents || loadings.reinscription || isFounded || preinscription.payment_type === 1"
                id="payment_receipt_number"
                :error="error('payment_receipt_number', { attribute: 'número de factura' })"
                v-model="preinscription.payment_receipt_number" />
        </div>

        <div class="flex space-x-2">
          <a-button v-if="isReinscription" mode="primary" @click="handleReinscription" :disabled="loadingStudents || loadings.reinscription || isFounded || shouldBeDisabled" :loading="loading">
            {{ isUpdating ? 'Actualizar cambios' : 'Reinscribir' }}
          </a-button>
          <a-button v-else mode="primary" type="submit" :disabled="loadingStudents || loadings.reinscription || isFounded" :loading="loading">
            {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
          </a-button>

          <a-button outlined :to="{ name: 'preinscriptions' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';

export default {
  data: () => ({
    preinscription: {
      first_name: '',
      second_name: '',
      last_name: '',
      second_last_name: '',
      email: '',
      id_document: '',
      gender: '',
      schedule_shift: '',
      address: {
        state: '',
        city: '',
        location: 'Urbanización',
        address: '',
        type: 'edificio',
        number: ''
      },
      birth_place: '',
      birth_date: '',
      personal_condition:'',
      phone: '',
      specialization_id: '',
      mobile_phone: '',
      payment_date: '',
      payment_amount: '',
      payment_type: '',
      payment_receipt_number: '',
      educational_title: '',
      educational_background: '',
      educational_institution_name: '',
      educational_graduation_date: '',
      educational_current_activity: '',
      representative_email: '',
      representative_name: '',
      representative_profession: '',
      representative_address:{
        state: '',
        city: '',
        location: 'Urbanización',
        address: '',
        type: 'edificio',
        number: ''
      },
      period_id: '',
      social_networks: {
        twitter: '',
        facebook: '',
        instagram: ''
      },
      payment_option: 'cash_down'
    },
    isUpdating: false,
    timeout: null,
    isInscription: false,
    isReinscription: false,
    isFounded:false,
    shouldBeDisabled: true,
    specializations: [],
    specializationsData: [],
    changeStudentSpecialization: {
      inscription_ids: [],
      target_specialization_id: null,
      target_pensum_id: null,
      target_semester_id: null
    },
    semesterNumber: '',
    pensumName: '',
    loadings: {
      enabledPeriod: false,
      reinscription: false,
    },
    period: {
      settings: {
        schedule_shifts: []
      }
    },
    hasCondition: false,
    sameAsStudentAddress: false,
    displayNotApplyName: false,
    displayNotApplyLastName: false,
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error',
      lang: 'lang'
    }),
    ...mapState({
      preinscriptions: state => state.preinscriptions.pagination.data,
      currentPreinscriptionOnState: state => state.preinscriptions.current,
      currentStudentOnState: state => state.students.current,
      student: state => state.students.pagination.data,
      loading: state => state.preinscriptions.loading,
      // loadingPeriods: state => state.periods.loading,
      loadingSpecialization: state => state.specializations.loading,
      // periods: state => state.periods.all,
      loadingStudents: state => state.students.loading ? state.students.loading : state.preinscriptions.loading,
      pensumsData: state => state.periods.pensums.data,
      semesters: state => state.semesters.all
    })
  },
  methods: {
    ...mapMutations({
      setStudent: 'students/SET'
    }),
    ...mapActions({
      fetchPreinscription: 'preinscriptions/fetch',
      fetchPreinscriptionStudents: 'preinscriptions/fetchAll',
      // fetchPerios: 'periods/index',
      store: 'preinscriptions/store',
      update: 'preinscriptions/update',
      removeError: 'removeError',
      fetchStudents: 'students/index',
      fetchStudent: 'students/show',
      reInscript: 'students/reInscript',
      fetchPensums: 'periods/enabledWithPensums',
      fetchSemesters: 'semesters/fetchSemesters'
    }),
    handleTypingSearch(search) {
      if (!this.isUpdating) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          if (search) {
            // this.fetchPreinscriptionStudents({search, limit: 5})
            this.fetchStudents({search, limit: 5, with: 'latestInscription.period,latestInscription.specialization,latestInscription.semester,latestInscription.section,inscriptions.semester'}).then(() => {
              this.isFounded = false;
              this.isReinscription = false;
              if(this.student.length === 1 && this.student[0].id_document === this.preinscription.id_document){
                const studentData = this.student[0];
                if(studentData.latest_inscription.period.id !== this.period.id && studentData.latest_inscription.semester.number === 6){
                  this.loadings.reinscription = true;
                  this.isReinscription = true;
                  this.fetchSemesters({ limit: 0 }).then(() => this.fetchPensums({ limit: 0 }).then(() => {
                    const filteredInscriptions = studentData.inscriptions.filter($0 => $0.period_id === studentData.latest_inscription.period_id);
                    this.changeStudentSpecialization.inscription_ids = filteredInscriptions.map(($0 => $0.id));
                    this.specializationsData = this.specializations.filter(spec => spec.id >=3 && studentData.latest_inscription.specialization_id !== spec.id).map(spec => ({ label: spec.title, value: spec.id }))
                    this.preinscription = {
                      ...studentData,
                      address: {
                        ...this.preinscription.address,
                        address: studentData.address
                      },
                      representative_address: {
                        ...this.preinscription.representative_address,
                        address: studentData.representative_address
                      },
                      payment_option: 'cash_down',
                      payment_date: '',
                      payment_amount: '',
                      payment_type: '',
                      payment_receipt_number: ''
                    };
                    this.loadings.reinscription = false
                  }));
                } else {
                  this.isFounded = true
                  this.preinscription = {
                    ...studentData,
                    address: {
                      ...this.preinscription.address,
                      address: studentData.address
                    },
                    representative_address: {
                      ...this.preinscription.representative_address,
                      address: studentData.representative_address
                    },
                    specialization_id: studentData.latest_inscription.specialization_id
                  }
                }
              } else {
                this.isFounded = false;
                this.specializationsData = this.specializations.filter(spec => spec.id !== 1 ).map(spec => ({ label: spec.title, value: spec.id }))
                Object.entries(this.preinscription).forEach(([key, value]) => {
                  if (value !== '' && key !== "id_document") {
                    switch(key){
                      case "social_networks":
                        this.preinscription.social_networks = {
                          twitter: '',
                          facebook: '',
                          instagram: ''
                        }
                        break;
                      case "address":
                        this.preinscription.address = {
                          state: '',
                          city: '',
                          location: 'Urbanización',
                          address: '',
                          type: 'edificio',
                          number: ''
                        }
                        break;
                      case "representative_address":
                        this.preinscription.representative_address = {
                          state: '',
                          city: '',
                          location: 'Urbanización',
                          address: '',
                          type: 'edificio',
                          number: ''
                        }
                        break;
                      case "payment_option":
                        this.preinscription.payment_option = 'cash_down';
                        break;
                      default: 
                        this.preinscription[key] = '';
                        break;
                    }
                  }
                });
              }
            })
          } else {
            Object.entries(this.preinscription).forEach(([key, value]) => {
              if (value !== '' && key !== "id_document") {
                switch(key){
                  case "social_networks":
                    this.preinscription.social_networks = {
                      twitter: '',
                      facebook: '',
                      instagram: ''
                    }
                    break;
                  case "address":
                    this.preinscription.address = {
                      state: '',
                      city: '',
                      location: 'Urbanización',
                      address: '',
                      type: 'edificio',
                      number: ''
                    }
                    break;
                  case "representative_address":
                    this.preinscription.representative_address = {
                      state: '',
                      city: '',
                      location: 'Urbanización',
                      address: '',
                      type: 'edificio',
                      number: ''
                    }
                    break;
                  case "payment_option":
                    this.preinscription.payment_option = 'cash_down';
                    break;
                  default: 
                    this.preinscription[key] = '';
                    break;
                }
              }
            });
          }
        }, 500);
      }
    },

    handleDisplay(first){
      if(first){
        this.displayNotApplyName = !this.displayNotApplyName;
      }else{
        this.displayNotApplyLastName = !this.displayNotApplyLastName;
      }
    },
    handleSelection(selectedValue, first) {
      if(first){
        if(this.preinscription.second_name !== selectedValue){
          document.getElementById('second_name').value = selectedValue;
        }
        this.displayNotApplyName = !this.displayNotApplyName;
        this.preinscription.second_name = selectedValue;
      }else{
        if(this.preinscription.second_last_name !== selectedValue){
          document.getElementById('second_last_name').value = selectedValue;
        }
        this.displayNotApplyLastName = !this.displayNotApplyLastName;
        this.preinscription.second_last_name = selectedValue;
      }
    },
    handleOutsideClick(event) {
      if (
        event.target.id !== "second_last_name_arrow"
        && event.target.id !== "second_last_name"
        && event.target.id !== "second_name_arrow"
        && event.target.id !== "second_name"
      ) {
        this.displayNotApplyName = false;
        this.displayNotApplyLastName = false;
      }
    },

    clearInputs() {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(input => {
        input.value = '';
      });
    },

    handleShouldBeDisabled() {
      let shouldBeDisabled; 
      if(this.preinscription.payment_type != 1){
        shouldBeDisabled = this.changeStudentSpecialization.target_specialization_id === null
            || this.preinscription.payment_date === ''
            || this.preinscription.payment_amount === ''
            || this.preinscription.payment_type === ''
            || this.preinscription.payment_receipt_number === ''
      } else {
        shouldBeDisabled = this.changeStudentSpecialization.target_specialization_id === null
            || this.preinscription.payment_date === ''
            || this.preinscription.payment_amount === ''
            || this.preinscription.payment_type === ''

      }
      this.shouldBeDisabled = shouldBeDisabled
    },

    handleReinscription() {
      const studentData = {
            ...this.changeStudentSpecialization,
            payment_date: this.preinscription.payment_date,
            payment_amount: this.preinscription.payment_amount,
            payment_type: this.preinscription.payment_type,
            payment_receipt_number: this.preinscription.payment_receipt_number
          }

      this.$repository.inscriptions
        .reInscription(studentData)
        .then(() => {
          Vue.prototype.$message.success({
            title: `Resinscripcion completada con éxito`,
            message: `El estudiante ${this.preinscription.first_name} ${this.preinscription.last_name} ha sido reinscrito`,
          })

          Object.keys(this.preinscription).forEach($0 => {
            this.preinscription[$0] = ''
          })
          Object.keys(this.changeStudentSpecialization).forEach($0 => {
            this.changeStudentSpecialization[$0] = null
          })

          this.clearInputs();
          this.isReinscription = false;

        })
    },
    handleSameAsStudentAddress(checked) {
      this.sameAsStudentAddress = checked;
    },

    submit() {
      if (this.$route.params.id) {
        this.reporsitory
      } else {
        let studentData = { ...this.preinscription };
        if(this.preinscription.second_name === "No aplica"){
          studentData.second_name = ''
        }
        if(this.preinscription.second_last_name === "No aplica"){
          studentData.second_last_name = ''
        }
        studentData.address = Object.values(studentData.address).join(', ');
        if(this.sameAsStudentAddress){
          studentData.representative_address = studentData.address;
        } else{
          studentData.representative_address = Object.values(studentData.representative_address).join(', ');
        }

        if (this.isInscription) {
          this.reInscript(studentData)
          return
        }
        if (this.isUpdating) {
          this.update(studentData)
        } else {
          this.store(studentData)
        }
      }
    },
  },
  watch: {
    currentPreinscriptionOnState(val) {
      this.isFounded = false;
      this.isReinscription = false;
      this.preinscription = {...val}
    },
    students(val) {
      this.isFounded = false;
      this.isReinscription = false;
      let found = val.find(it => it.id_document.includes(this.preinscription.id_document))

      this.setStudent({
        first_name: '',
        second_name: '',
        last_name: '',
        second_last_name: '',
        email: '',
        gender: '',
        schedule_shift: '',
        address: {
          address: '',
          city: '',
          state: '',
          zip_code: ''
        },
        birth_place: '',
        birth_date: '',
        personal_condition: '',
        phone: '',
        mobile_phone: '',
        fax: '',
        payment_date: '',
        payment_amount: '',
        payment_type: '',
        payment_receipt_number: '',
        educational_title: '',
        educational_background: '',
        educational_institution_name: '',
        educational_graduation_date: '',
        educational_current_activity: '',
        representative_email: '',
        representative_name: '',
        representative_profession: '',
        representative_address: {
          address: '',
          city: '',
          state: '',
          zip_code: ''
        },
        period_id: '',
        id_document: this.preinscription.id_document,
        social_networks: {
          instagram: '',
          facebook: '',
          twitter: ''
        },
        payment_option: 'cash_down'
      })

      this.isInscription = false

      if (found && this.preinscription.id_document !== '') {
        this.setStudent(found)
        this.isInscription = true
      }
    },
    currentStudentOnState(val) {
      this.isFounded = false;
      this.isReinscription = false;
      this.preinscription = val
    },
    preinscriptions(val){
      this.isFounded = false;
      this.isReinscription = false;
      if(val.length === 1 && val[0].id_document === this.preinscription.id_document){
        this.preinscription = val[0]
      } else {
        Object.keys(this.preinscription).forEach($0 => {
          this.preinscription[$0] = ''
        })
      }
    },
    preinscription: {
      deep: true,
      handler(val){
        if(val.payment_type === 1){
          this.preinscription.payment_receipt_number = ''
          document.querySelector('#payment_receipt_number').value = '';
        }
        if(this.isReinscription && val){
          setTimeout(() => {
            this.handleShouldBeDisabled();
          }, 100)
        }
      }
    },
    'preinscription.specialization_id'(val){
      if(val && this.isReinscription){
        const pensumData = this.pensumsData.filter(pensum => pensum.specialization_id === val);
        const semesterData = this.semesters.filter(semester => semester.pensum_id === pensumData[0].id && semester.number === 3)
        this.semesterNumber = semesterData[0].number;
        this.pensumName = pensumData[0].name;
        this.changeStudentSpecialization.target_specialization_id = val
        this.changeStudentSpecialization.target_pensum_id = pensumData[0].id
        this.changeStudentSpecialization.target_semester_id = semesterData[0].id
      } else{
        this.changeStudentSpecialization.target_specialization_id = null;
        this.changeStudentSpecialization.target_pensum_id = null;
        this.changeStudentSpecialization.target_semester_id = null;
      }
    },
    hasCondition(val) {
      if (!val) {
        this.preinscription.personal_condition = '';
      }
    }
  },
  created() {

    const id = this.$route.query.update
    // this.fetchPerios({ limit: 0, enabled: true })
    
    this.$repository.specializations
      .index({ limit: 0 }).then(({ data }) => {
        this.specializations = data;
        this.specializationsData = data.filter(spec => spec.id !== 1).map(spec => ({ label: spec.title, value: spec.id }))
      })

    this.loadings.enabledPeriod = !this.loadings.enabledPeriod
    this.$repository.periods
      .enabled()
      .then(({ data }) => {
        this.period = {
          ...data,
          settings: {
            ...data.settings,
            schedule_shifts: data.settings.schedule_shifts.map($0 => ({
              label: this.lang.common.schedule_shifts[$0],
              value: $0
            }))
          }
        }
      })
      .finally(() => this.loadings.enabledPeriod = !this.loadings.enabledPeriod)

    if (this.$route.params.id) {
      this.fetchStudent({ id: this.$route.params.id })
        .then((val) => {
          console.log(val)
        })
      // console.log(this.currentStudentOnState)
    } else {
      if (id) {
        this.isUpdating = true

        let found = this.preinscriptions.find(preinscription => preinscription.id === parseInt(id))

        if (found) {
          if (!found.social_networks) {
            found.social_networks = this.preinscription.social_networks
          }

          this.preinscription = {...found}
        } else {

          this.fetchPreinscription({
            id,
            query: {
              with: `socialNetworks`
            }
          })
        }
      }
    }

  }
}
</script>
